import { CurrencyPipe, UpperCasePipe } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatRadioChange, MatRadioModule } from '@angular/material/radio'
import { DateTime } from 'luxon'
import { ShippingService } from 'src/app/services/shipping.service'
import { ShippingOption } from 'src/app/types/shipping'

@Component({
  selector: 'gwc-shipping-options',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatRadioModule,
    ReactiveFormsModule,
    UpperCasePipe
  ],
  templateUrl: './shipping.options.component.html',
  styleUrl: './shipping.options.component.scss'
})

export class ShippingOptionsComponent {
  @Input() description: string
  @Input() field: FormControl
  @Input() options: ShippingOption[]
  @Input() submitted: boolean
  @Input() type: 'static' | 'dynamic' = 'static'
  @Input() bound: 'inbound' | 'outbound'
  @Input() hide_logo: boolean = false
  
  constructor(
    private shippingService: ShippingService  
  ) {}

  public displayTime(time) {
    return DateTime.fromFormat(time, "HH:mm:ss").toFormat("HH:mm a")
  }

  onShippingChanged(event: MatRadioChange): void {
    const field = this.bound === 'inbound' ? 'inbound_price' : 'speed_price'
    if (this.type === 'dynamic') {
        const filtered = this.options
					.filter(option => option.id === event.value)[0]
    
        if (filtered) {
          this.shippingService.addShippingPriceControl(filtered.cost, field)
        }
    } else {
      this.shippingService.removeShippingPriceControl(field)
    }
  }
}
