@if (aarp_loader) {
	<mat-spinner class="gw-checkout-button__load gw-checkout-button__load--brand"
		[diameter]="60"></mat-spinner>
} @else {
	@if (display_form) {
		<form [formGroup]="order" #orderForm="ngForm">
			<div formArrayName="travelers">
				@for (traveler of travelers.controls; track traveler; let traveler_index = $index) {
					<div class="gw-chkout-main gw-chkout-traveler">
						<div class="gw-chkout-traveler__name">
							@if (traveler.value.info.first_name && traveler.value.info.last_name) {
								{{ traveler.value.info.first_name }} {{ traveler.value.info.last_name }}
							} @else {
								Applicant {{ traveler_index + 1 }}
							}
						</div>
						<div [formGroupName]="traveler_index">
							<div class="gw-chkout-traveler__info">
								<h3 class="gw-chkout-label">Applicant Info</h3>
								<div formGroupName="info">
									<div class="gw-chkout-form">
										<mat-form-field class="gw-chkout-field gw-chkout-field--half"
											[hideRequiredMarker]="true">
											<mat-label>
												First Name
											</mat-label>
											<input matInput
												name="first"
												pattern="[A-Za-z\-\s]*"
												formControlName="first_name">
											<mat-error>Please provide the applicant's first name.</mat-error>
										</mat-form-field>
										<mat-form-field class="gw-chkout-field gw-chkout-field--half"
											[hideRequiredMarker]="true">
											<mat-label>
												Last Name
											</mat-label>
											<input matInput
												name="last"
												pattern="[A-Za-z\-\s]*"
												formControlName="last_name">
												<mat-error>Please provide the applicant's last name.</mat-error>
										</mat-form-field>
									</div>
									<div class="gw-chkout-form">
										<mat-form-field class="gw-chkout-field"
											[hideRequiredMarker]="true">
											<mat-label>
												Date of Birth (MM/DD/YYYY)
											</mat-label>
											<input matInput
												mask="M0/d0/0000"
												[dropSpecialCharacters]="false"
												name="dob"
												type="tel"
												formControlName="date_of_birth">
											<mat-error>
												@if (checkTraveler(traveler_index, 'date_of_birth', 'minlength') || checkTraveler(traveler_index, 'date_of_birth', 'invalid')) {
													Please enter a valid date of birth.
												} @else if (checkTraveler(traveler_index, 'date_of_birth', 'required')) {
													Please enter applicant's date of birth.
												} @else if (checkTraveler(traveler_index, 'date_of_birth', 'future')) {
													Applicant's date of birth cannot be in the future.
												} @else if (checkTraveler(traveler_index, 'date_of_birth', 'past')) {
													Applicant's date of birth must be after  01/01/1900.
												}
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div [formGroup]="itinerary"
									class="gw-chkout-form">
									<mat-form-field class="gw-chkout-field">
										<mat-label>Travel Destination</mat-label>
										@if (itinerary.controls.country.value?.value) {
											<span class="fi fi-{{ itinerary.controls.country.value.value | lowercase }} gw-chkout-field__flag">
											</span>
										}
										<input #destinationInput
											matInput
											type="text"
											[formControl]="order.controls.itinerary.controls.country"
											[matAutocomplete]="auto"
											(input)="filter()"
											(focus)="filter()">
										<mat-autocomplete requireSelection #auto="matAutocomplete"
											[displayWith]="displayFn">
											@for (country of filteredCountries; track country) {
												<mat-option [value]="country">
													<span class="fi fi-{{ country.value | lowercase }}"></span>
													{{ country.label }}
												</mat-option>
											}
										</mat-autocomplete>
										<mat-hint>Optional</mat-hint>
									</mat-form-field>
									<mat-form-field class="gw-chkout-field gw-chkout-field--half">              
										<mat-label>Departure Date (MM/DD/YYYY)</mat-label>
										<input matInput 
											[min]="minDate" 
											[max]="maxDate" 
											name="departure_date"
											formControlName="start_date"
											[dropSpecialCharacters]="false"
											(change)="updateDate($event, 'type')"
											mask="M0/d0/0000">
										<input matInput 
											style="display: none"
											[min]="minDate" 
											[max]="maxDate" 
											name="departure_datepicker"
											type="tel"
											[value]="date"
											(dateChange)="updateDate($event, 'select')"
											[matDatepicker]="picker">
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-datepicker #picker [calendarHeaderComponent]="datepickerHeader"></mat-datepicker>
										<mat-hint>Optional</mat-hint>
										<mat-error>
											@if (checkItinerary('minlength') || checkItinerary('invalid')) {
												Please enter a valid departure date.
											} @else if (checkItinerary('future')) {
												Departure date must be before {{ maxDate | date:'MM/dd/yyyy' }}.
											} @else if (checkItinerary('past')) {
												Departure date must be after {{ minDate | date:'MM/dd/yyyy' }}.
											}
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div formArrayName="products">
								@for ( product of traveler.controls.products.controls; track product; let product_index = $index) {
									<div class="gw-chkout-product">
										<div class="gw-chkout-product__label">
											<h2 [ngSwitch]="product.value.type">
												<svg>
													<use [attr.xlink:href]="'assets/svg/product.svg#' + product.value.type" />
												</svg>
												<span *ngSwitchCase="'passport'">
													<b>U.S. Passport</b>
												</span>
												<span *ngSwitchCase="'ca_passport'">
													<b>CA Passport</b>
												</span>
												<span *ngSwitchCase="'visa'">
													<b>{{ product.value.country | locationName }} Travel Visa</b>
												</span>
												<span *ngSwitchCase="'idp'">
													<b>International Driving Permit</b>
												</span>
												@if (!['idp'].includes(product.value.type)) {
													Application
												}
											</h2>
											@if (traveler.value.products.length > 1) {
												<button mat-icon-button
													(click)="removeProduct(traveler, product_index)"
													class="gw-chkout-product__remove">
												</button>
											}
										</div>
										<div [formGroupName]="product_index">
											<!-- Select type -->
											@switch (product.value.type) {
												@case ('passport') {
													<gwc-passport-type [traveler]="traveler"
														[passport]="product"
														[submitted]="submitted">
													</gwc-passport-type>
												}
												@case ('ca_passport') {
													<gwc-ca-passport-type [traveler]="traveler"
														[passport]="product"
														[submitted]="submitted">
													</gwc-ca-passport-type>
												}
												@case ('visa') {
													<gwc-visa-type [traveler]="traveler"
														[visa]="product"
														[submitted]="submitted">
													</gwc-visa-type>
												}
												@case ('idp') {
													<gwc-idp-type [traveler]="traveler"
														[product]="product"
														[submitted]="submitted">
													</gwc-idp-type>
												}
												@default {
													<gwc-passport-type [traveler]="traveler"
														[passport]="product"
														[submitted]="submitted">
													</gwc-passport-type>
												}
											}
											@if (getProductDetails(traveler, product, 'addons')?.length > 0) {
												<div class="gw-product-type__section">
													<h3 class="gw-chkout-label">Select Add-ons</h3>
													<mat-selection-list formControlName="addons"
														(selectionChange)="toggleAddons($event, traveler, product)"
														class="gw-chkout-addons">
														@for (addon of getProductDetails(traveler, product, 'addons', true); track addon.uuid) {
															<mat-list-option togglePosition="before"
																color="primary"
																[value]="addon.uuid"
																[disabled]="addon.required || addon.slug === 'service_fee'"
																[disableRipple]="true"
																[selected]="product.value.addons.includes(addon.uuid)"
																[ngClass]="{'gw-addon-hidden': isAddonHidden(addon, traveler, product)}">
																<span>
																	<div class="gw-chkout-checkbox__top">
																		<strong class="gw-chkout-checkbox__label">
																			{{ addon.label }} {{ addon.required  ? '(required)' : ''}}
																		</strong>
																		<strong class="gw-chkout-checkbox__price">
																			{{ addon.price/100 | currency:'USD':'symbol':'1.2-2' }}
																		</strong>
																	</div>
																	<div class="gw-chkout-checkbox__description">
																		{{ addon.description }}
																	</div>
																</span>
															</mat-list-option>
														}
													</mat-selection-list>
												</div>
											}
										</div>
									</div>
								}
							</div>
						</div>
						@if (up_sell_btns) {
							<div class="gw-chkout-traveler__actions gw-chkout-product__topdivider">
								@if (!travelerService.hasPassport(traveler.getRawValue())) {
									<button mat-button
										(click)="addProduct(traveler, 'passport', $event)"
										class="gw-chkout-button gw-chkout-traveler__cta gw-chkout-traveler__cta--passport">
										@if (adding_product) {
											<mat-spinner class="gw-checkout-button__load gw-checkout-button__load--grey"
												[diameter]="20">
											</mat-spinner>
										} @else {
											<span>
												Add Passport Service
											</span>
										}
									</button>
								}

								<button mat-button
									(click)="addProduct(traveler, 'visa', $event)"
									class="gw-chkout-button gw-chkout-traveler__cta gw-chkout-traveler__cta--visa">
									Add Travel Visa Service
								</button>
							</div>
						}
					</div>
				}
			</div>
		</form>
	}
}
