import { Component, Input } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-visa-layout',
  templateUrl: './visa.layout.component.html',
  styleUrls: ['./visa.layout.component.scss'],
  standalone: true
})

export class VisaLayoutComponent {
  @Input() bg: string
  @Input() country: { label: string, value: string }

  protected readonly description: string = environment.landing_pages?.visa?.use_desc_on_country_page ? environment.landing_pages?.visa?.description : ''

}
