import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core'
import { UntypedFormGroup, UntypedFormArray, FormGroupDirective, UntypedFormBuilder, Validators, FormGroup, FormArray } from '@angular/forms'
import { DatepickerHeader } from '../datepicker-header/datepicker-header.component'
import { Observable, Subscription, forkJoin } from 'rxjs'
import { CommonService } from 'src/app/services/common.service'
import { OrderService } from 'src/app/services/order.service'
import { environment } from 'src/environments/environment'
import { visa_types } from 'src/app/data/visa.types'
import { ActivatedRoute, Router } from '@angular/router'
import { HeaderService } from 'src/app/services/header.service'
import { TagService } from 'src/app/services/tag.service'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { FilterAttributeData, Location } from 'src/app/types/products'
import { TravelerService } from 'src/app/services/traveler.service'
import { visa_entries } from 'src/app/data/visa.entries'
import { AuthService } from 'src/app/services/auth.service'
import { MatSelectionListChange } from '@angular/material/list'
import { HelperService } from 'src/app/services/helper.service'
import { ItineraryForm, OrderForm, TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { FormService } from 'src/app/services/form.service'
import * as _ from 'lodash'
import { DateTime } from 'luxon'
import { Addon } from 'src/app/models/addon.model'

@Component({
  selector: 'gw-chkout-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('orderForm') orderForm: FormGroupDirective
  @ViewChild('destinationInput') destinationInput: ElementRef<HTMLInputElement>

  public headline: string = environment['step-1'] && environment['step-1'].main ? environment['step-1'].main : "Select Service Options"
  public description: string = environment['step-1'] && environment['step-1'].secondary ? environment['step-1'].secondary : "The fast, convenient way to expedite your application."
  public order: FormGroup<OrderForm>

  public countries: Location[]
  public filteredCountries: Location[]
  public submitted: boolean
  public subscription: Subscription
  public date 
  public entries = visa_entries
  public visa_types = visa_types
  public adding_product: boolean = false
  public up_sell_btns: boolean = environment.up_sell_btns
  public domain: string = environment.source.domain
  public itinerary_filled: boolean = false
  public display_form: boolean = this.domain !== 'aarp' || true
  public aarp_loader: boolean = this.domain === 'aarp' && false
  // public aarp_login: string | undefined = environment.aarp_login

  public minDate: string = this.helperService.getFollowingBusinessDays(3).toFormat('yyyy-MM-dd')
  public maxDate = new Date(2038, 11, 31)
  datepickerHeader = DatepickerHeader

  constructor(
    private formService: FormService,
    private commonService: CommonService,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private tagService: TagService,
    private productService: ProductService,
    private shippingService: ShippingService,
    private formBuilder: UntypedFormBuilder,
    public travelerService: TravelerService,
    private router: Router,
    private authService: AuthService,
    private helperService: HelperService
  ) {
    this.preFill()
  }

  ngOnInit() {
    this.tagService.utagPageView('travel:expedited travel:select service')

    // if (this.domain === 'aarp' && !this.order.value.verified) {
      // this.checkAARPMembership()
    // } else {
      this.display_form = true
      this.aarp_loader = false
    // }
    
    this.getCountries()
    this.listenToSubmit()
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    // Listen for a selected service to start the timer count down
    // this.orderService.onSelectedService().subscribe(service => {
    //   if(service != null) this.headerService.startTimer(false)
    // })
  }

  private checkAARPMembership() {
    let code = this.activatedRoute.snapshot.queryParams.code
    let traveler = this.travelers.controls[0] as UntypedFormGroup
    let product = (traveler.controls.products  as UntypedFormArray).controls[0] as UntypedFormGroup
    
    if (product.getRawValue().type === 'passport') {
      this.activatedRoute.queryParams.subscribe(
        value => {
          this.productService.getProducts({
            citizenship: traveler.getRawValue().info.citizenship,
            residency: traveler.getRawValue().info.residency,
            residence_country: 'US',
            type: 'passport',
            country: 'US'
          }).subscribe((response) => {
              let desired_product = response.filter(item => item.subtype === value.state)[0]

              if (desired_product) {
                product.patchValue({subtype: value.state, product_uuid: desired_product.uuid})
              }
            })
        })
    }

    if (code) {
      this.authService.checkAARPMembership(code)
        .subscribe((response) => {
          this.aarp_loader = false
          
          if (response.data && response.data.activeMember === 'true') {
            this.order.controls.verified.patchValue(true)
            this.orderService.saveCart()
            this.display_form = true
          }
        })
    } else {
      this.aarp_loader = false
    }
  }

  private getCountries() {
    this.commonService.getCountries()
      .subscribe( res => {
        this.countries = res
      })
  }

  public filter(): void {
    const filterValue = this.destinationInput.nativeElement.value.toLowerCase()
    this.filteredCountries = this.countries.filter(country => country.label.toLowerCase().includes(filterValue))
  }

  private listenToSubmit() {
    this.subscription = this.orderService.submittedSubject.subscribe((status: boolean) => {
      this.submitted = true
      this.orderForm.onSubmit(undefined)
      this.orderService.setFormValidity(this.orderForm.form.valid)

      if (!this.orderForm.form.valid) {
        this.orderService.scrollToFirstError()
      }
    })
  }

  private preFill(): void {
    this.order = this.orderService.orderForm

    // Save query params. Needed for marketing and partner reporting
    const query = this.activatedRoute.snapshot.queryParamMap
    const params = this.activatedRoute.snapshot.queryParams
    this.order.controls.params.patchValue({...this.order.controls.params.value, ...params})

    // If cart doesn't have traveler add one with an empty product
    if (this.order.controls.travelers.length === 0) {
      let traveler = this.formService.getTravelerForm()
      traveler.controls.info.patchValue({
        citizenship: environment.main_product?.citizenship || 'US',
        residence_country: environment.main_product?.residence_country || 'US'
      })
      this.order.controls.travelers.push(traveler)
    }

    // Try to pre-fill cart if it only has one traveler
    if (this.order.controls.travelers.length === 1) {
      let added_to_card: boolean = false
      let traveler = this.order.controls.travelers.controls[0]

      // If traveler doesn't have any products add checkout's main product
      if (traveler.controls.products.length === 0) {
        let product = this.formService.getProductForm(environment.main_product?.type || 'passport')
        product.patchValue({
          country: environment.main_product?.country || 'US'
        })

        traveler.controls.products.push(product)
        added_to_card = true
      }

      // If traveler only has one product try to prefill from the URL params
      if (traveler.controls.products.length === 1) {
        let product = traveler.controls.products.controls[0]

        const type = product.controls.product_uuid.getRawValue() ? null : query.get('type') || query.get('product')
        const service = product.controls.option_uuid.getRawValue() ? null : query.get('service')

        if (type || service) {
          const products = this.productService.checkForProducts({
            type: product.controls.type.getRawValue() || 'passport',
            country: product.controls.country.getRawValue() || 'US',
            citizenship: traveler.controls.info.controls.citizenship.getRawValue() || 'US',
            residency: traveler.controls.info.controls.residency.getRawValue() || '', //'FL'
            residence_country: traveler.controls.info.controls.residence_country.getRawValue() || 'US',
          })

          // Pre-fill type/product
          if (type) {
            // Find product matching the URL subtype
            if (products) {
              let filtered = products.filter(item => item.subtype === type)[0]
              
              // If product exists pre-fill the product_uuid
              if (filtered) {
                product.controls.product_uuid.patchValue(filtered.uuid)
                added_to_card = true

                if (filtered.options?.length === 1) {
                  product.controls.option_uuid.patchValue(filtered.options[0].uuid)
                } else if (filtered.services?.length) {
                  product.controls.option_uuid.patchValue(filtered.services[0].uuid)
                } else if (type === 'passport-renewal' && environment.source.domain === 'aarp') {
                  // Select Expedited Service for AARP customers
                  let option = filtered.options.filter(option => option.slug === 'expedited_service')[0]
  
                  if (option) {
                    product.controls.option_uuid.patchValue(option.uuid)
                  }
                }
              }
            }
          }

          // Pre-fill service
          if (service) {
            const options = this.getProductDetails(traveler, product, 'services')
            const filtered = options.filter(option => option.slug.includes(service))[0]
            added_to_card = true

            if (filtered) {
              product.controls.option_uuid.patchValue(filtered.uuid)
              product.controls.option_uuid.updateValueAndValidity()
            }
          }
        } else if (environment.main_product) {
          const products = this.productService.checkForProducts({
            type: environment.main_product.type,
            country:environment.main_product.country,
            citizenship: environment.main_product.citizenship,
            residency: '', //'FL'
            residence_country: environment.main_product.residence_country
          })

          if (products.length === 1) {
            product.controls.product_uuid.patchValue(products[0].uuid)
          }
          
          if (products?.[0]?.services?.length === 1) {
            product.controls.option_uuid.patchValue(products[0].services[0].uuid)
          }
        }

        if (added_to_card) {
          try {
            if (product.controls.product_uuid.getRawValue() && product.controls.option_uuid.getRawValue()) {
              this.productService.getGAItem(traveler.getRawValue(), product.getRawValue())
                .subscribe(response => {
                  this.tagService.changeToCart(response, 'add_to_cart')
                })
            }
          } catch (exception) {}
        }
      }
    }

    // Coupon logic
    // coupon that is passed in the url (coupon=aaacruise)
    // coupon that is applied for a domain (environment.source?.auto_applied_code)
    // coupon applied by utm_campaign/utm_referrer
    // coupon saved in the cart object

    const joined_params = this.order.controls.params.getRawValue()
    let coupon: string = query.get('coupon') 
    let gw_ref: string = query.get('gw_ref') 
    let coupon_requests: Observable<any>[] = []

    if (gw_ref) {
      coupon_requests.push(this.orderService.applyPromoCode(gw_ref, true))
    } else {
      if (coupon) {
        coupon_requests.push(this.orderService.applyPromoCode(coupon))
        coupon = null
      }
  
      coupon = joined_params?.coupon !== query.get('coupon') ? joined_params?.coupon : null
  
      if (coupon) {
        coupon_requests.push(this.orderService.applyPromoCode(coupon))
        coupon = null
      }
  
      switch (this.domain) {
        case 'rmp': 
          let campaign: string = query.get('utm_campaign')
  
          if (campaign && campaign.includes('followup')) {
            coupon = '455e80750a79'
          } else {
            let referer: string = query.get('utm_referrer')
  
            if (referer && referer.includes('utm_term=10')) {
              coupon = 'cdcf0a4f4d34'
            }
          }
  
          break
        case 'fedex':
          if (joined_params?.utm_campaign?.includes('10percent')) {
            coupon = 'be3c6b28a856'
          }
  
          break
        case 'rmp_ca':
          if (joined_params?.utm_campaign?.includes('followupca')) {
            coupon = '0a593d91c92b'
          }
          
          break
        default:
          coupon = environment.source?.auto_applied_code
          break
      }
  
      if (coupon) {
        coupon_requests.push(this.orderService.applyPromoCode(coupon))
      }
    }

    forkJoin(coupon_requests).subscribe({
      next: response => {
        let i: number = 0

        while (!response[i] && i < response.length) {
          i++
        }

        if (response[i]) {
          this.orderService.couponSubject.next(response[i])
        }
      },
      error: error => {
        console.log(error)
      }
    })

    let external = query.get('external')

    if (external) {
      this.order.controls.external.patchValue(external)
    }
  }

  public displayFn(item): string | null {
    return item ? item.label : null
  }

  public displayDuration(from, to): string {
    return this.helperService.parseDuration(from, to)
  }

  get travelers(): FormArray<FormGroup<TravelerForm>> {
    return this.order.controls.travelers
  }

  get itinerary(): FormGroup<ItineraryForm> {
    return this.order.controls.itinerary
  }

  public checkTraveler(index: number, field: string, error: string): boolean {
    const errors = this.travelers.controls[0].controls.info.controls.date_of_birth.errors

    if (!errors || (errors.required && error != 'required')) {
      return false
    } else {
      return errors[error] ? errors[error] : false
    }
  }

  public checkItinerary(error: string): boolean {
    const errors = this.itinerary.controls.start_date.errors

    if (!errors || (errors.required && error != 'required')) {
      return false
    } else {
      return errors[error] ? errors[error] : false
    }
  }

  public updateDate(event, type: string) {
    if (type == 'type') {
      const value = this.itinerary.controls.start_date.value
      
      if (DateTime.fromISO(value).isValid && value.length === 10) {
        this.date = new Date(value)
      }
    } else {
      const targetValue = event.targetElement.value
      this.itinerary.controls.start_date.patchValue(this.helperService.formatDate(targetValue))
    }
  }

  public getProductDetails(traveler, product, detail: string, filter = false) {
    let productDetails = this.productService.getProductDetails(traveler.value.info, product.value, detail)

    if (detail === 'addons' && filter === true) {
      let passportCard: Addon = null
      for (const addonId of product.value.addons) {
        passportCard = productDetails.find(pd => pd.uuid === addonId && pd.slug === 'passport_card')
        if (passportCard) break
      }
      productDetails = productDetails.filter(pd => pd.condition !== (passportCard ? '!passportCard' : 'passportCard'))
    }

    return productDetails
  }

  public addProduct(traveler: UntypedFormGroup, type: string, event: Event) {
    event.preventDefault()

    if (this.adding_product) return

    switch (type) {
      case 'passport':
        this.adding_product = true

        let product: FilterAttributeData = {
          citizenship: traveler.value.info.citizenship,
          residency: traveler.value.info.residency,
          residence_country: 'US',
          type: 'passport',
          country: 'US'
        }
    
        this.productService.getProducts(product)
          .subscribe((response) => {
            this.adding_product = false
            let products: any = traveler.controls.products as UntypedFormArray
            let passport = this.formBuilder.group({
              type: [type, Validators.required],
              country: ['US', Validators.required],
              product_uuid: ['', Validators.required],
              option_uuid: ['', Validators.required],
              addons: [[]]
            })
    
            products.push(passport)
          })

        break
      case 'visa': 
        this.orderService.saveCart()
        this.router.navigate(['/travel-visa'])
    }
  }

  public removeProduct(traveler: FormGroup<TravelerForm>, index: number) {
    const product = traveler.getRawValue().products[index]

    traveler.controls.products.removeAt(index)

    try {
      this.productService.getGAItem(traveler.value, product)
        .subscribe(response => {
          this.tagService.changeToCart(response, 'remove_from_cart')
        })
    } catch (exception) {}

    this.shippingService.checkShipping(traveler.getRawValue(), true)

    if (product.type === 'visa') {
      let destination = this.itinerary.value.country

      if (destination && destination.value === product.country) {
        let next_visa = traveler.value.products.find((product) => product.type === 'visa')

        if (next_visa) {
          let country = this.commonService.getCountry(next_visa.country)

          if (country) {
            this.itinerary.controls.country.patchValue(country)
          }
        } else {
          this.itinerary.controls.country.patchValue({value: '', label: ''})
        }
      }
    }

    this.orderService.saveCart()
  }

  public isAddonHidden(addon, traveler: FormGroup<TravelerForm>, product: FormGroup<TravelerProductForm>) {
    const addons = this.getProductDetails(traveler, product, 'addons')

    if (product.getRawValue().type === 'passport') {
      const passport_card = addons.filter((addon) => addon.slug == 'passport_card')[0]
      const card_selected = product.controls.addons.getRawValue().includes(passport_card.uuid)
  
      if (addon.condition && (
        (addon.condition === 'passportCard' && !card_selected) ||
        (addon.condition === '!passportCard' && card_selected)
      )) {
        return true
      }
    }

    return addon.slug === 'service_fee'
  }

  public toggleAddons($event: MatSelectionListChange, traveler: UntypedFormGroup, product: UntypedFormGroup) {
    const addons: Addon[] = this.getProductDetails(traveler, product, 'addons')
    let selectedAddons: Addon[] = addons.filter(addon =>
      $event.source.selectedOptions.selected.some(option => option.value === addon.uuid)
    )
    const selectedAddon: Addon = addons.find(addon => addon.uuid === $event.options[0].value)
  
    if (selectedAddon) {
      const isSelected: boolean = $event.options[0].selected
  
      switch (selectedAddon.slug) {
        case 'government_fee':
          if (isSelected) {
            const service_fee_addon = addons.find(addon=> addon.slug === 'service_fee')

            if (service_fee_addon) {
              selectedAddons.push(service_fee_addon)
            }
          } else {
            selectedAddons = selectedAddons.filter(addon => addon.slug !== 'service_fee')
          }
          break

        case 'passport_card':
          const gov_fee_addon = addons.find(addon => addon.condition === (isSelected ? 'passportCard' : '!passportCard'))

          if (gov_fee_addon) {
            selectedAddons.push(gov_fee_addon)
          }
          break
      }
    }
  
    product.controls.addons.patchValue(
      selectedAddons.map(a => a.uuid)
    )
  }

  public limitedProducts(traveler, product): boolean {
    let products = this.getProductDetails(traveler, product, 'products')

    return products.length === 2
  }

  public onDestinationUpdated(): void {
    if (this.itinerary_filled) {
      let country = this.itinerary.value.country

      if ((country && country.label) || !country ) {
        this.orderService.saveCart()
      }
    }
  }
}
