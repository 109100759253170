<div class="gw-chkout-funnel"
  [ngClass]="{'gw-chkout-funnel--photo': isRMPhotoEnv}">
  @if (data.step !== undefined && !isRMPhotoEnv) {
    <gw-chkout-steps
      [steps]="steps"
      [step]="data.step">
    </gw-chkout-steps>

    @if (data.step > 0) {
      <div class="gw-chkout-back"
        [routerLink]="getPreviousStep()"
        [queryParams]="data.previousParams">
        Previous Step
      </div>
    }
  }
  <div class="gw-chkout-funnel__form">
    @if (!isRMPhotoEnv) {
      <div class="blank-container">
        <gw-chkout-secure [headline]="data?.headline"
          [description]="data?.description">
        </gw-chkout-secure>
        <router-outlet></router-outlet>
      </div>
    } @else {
      <div class="gw-chkout-funnel__secure">
        <gw-chkout-secure [headline]="headline"
          [description]="description">
        </gw-chkout-secure>
      </div>
      <div class="gw-chkout-funnel__outlet">
        <router-outlet></router-outlet>
      </div>
    }

    <gw-chkout-summary [benefits]="data?.hide_summary"
      [next]="data?.next"
      [step]="data?.step"
      [ngClass]="{'gw-chkout-funnel__summary--desktop-only': isRMPhotoEnv,
        'gw-chkout-funnel__summary--last-steps': isRMPhotoEnv && showSummary()}">
    </gw-chkout-summary>
  </div>
  
  @if (trustpilot) {
    <gw-chkout-trustpilot [trustpilot]="trustpilot">
    </gw-chkout-trustpilot>
  }
</div>
