<div class="gw-chkout-main gw-chkout-ship">
	<h3 class="gw-chkout-label">
		{{ shipping_type() === 'basic' ? '' : 'Shipping' }} Address
	</h3>
	<p>
		@if (shipping_type() === 'basic') {
			Please keep in mind P.O. Boxes cannot be used.
		} @else if (idp) {
			Please provide shipping address for your new International Driving Permit.
		} @else {
			Your application is eligible to be mailed in for processing. Please enter your address to see the rates.
		}
	</p>   

	<form [formGroup]="shipping" #shippingForm="ngForm">
		<!-- Default google autcomplete -->
		@if (!shipping.controls.is_military?.value) {
			<!-- Show Map when mapURL becomes available. Only used with google autocomplete -->
			@if (mapURL) {
				<div class="gw-chkout-ship__map">
					<iframe [src]="mapURL"
						frameBorder="0"></iframe>
					<span class="preloader"></span>
				</div>
			}
			@if (!showShippingForm) {
				<gwc-google-address [addressControl]="shipping"
					type="shipping"
					(updateMap)="setMapURL($event)"
					(addressUpdated)="addressUpdated()">
				</gwc-google-address>
			} @else {
				<div class="gw-chkout-shipping__form">
					<div class="gw-chkout-form">
						<mat-form-field class="gw-chkout-field">
							<mat-label>Address Line 1</mat-label>
							<input matInput 
								[formControl]="shipping.controls.address.controls.address_1">
								@if (shipping.controls.address.controls.address_1.errors?.['required']) {
									<mat-error>Please provide an address.</mat-error>
								} @else if (shipping.controls.address.controls.address_1.errors?.['pattern']) {
									<mat-error>Special characters are not allowed.</mat-error>
								}
							</mat-form-field>
	
						<mat-form-field class="gw-chkout-field gw-chkout-field--apt">
							<mat-label>Apt/Ste</mat-label>
							<input matInput 
								[formControl]="shipping.controls.address.controls.address_2">
								@if (shipping.controls.address.controls.address_2.errors?.['pattern']) {
									<mat-error>Special characters are not allowed.</mat-error>
								}
						</mat-form-field>
					</div>

					<div class="gw-chkout-form">
						<mat-form-field class="gw-chkout-field">
							<mat-label>City</mat-label>
							<input matInput 
								[formControl]="shipping.controls.address.controls.city">
								@if (shipping.controls.address.controls.city.errors?.['required']) {
									<mat-error>Please provide a city.</mat-error>
								} @else if (shipping.controls.address.controls.city.errors?.['pattern']) {
									<mat-error>Special characters are not allowed.</mat-error>
								}
						</mat-form-field>
	
						@if (shipping.controls.address.controls.country.value === 'US' 
							|| shipping.controls.address.controls.country.value === 'CA') {
							<mat-form-field class="gw-chkout-field">
								<mat-label>State</mat-label>
								<mat-select [formControl]="shipping.controls.address.controls.state">
									@for (state of states; track state.value) {
										<mat-option [value]="state.value">
											{{ state.label }}
										</mat-option>
									}
								</mat-select>
								@if (shipping.controls.address.controls.state.errors?.['required']) {
									<mat-error>Please select a state.</mat-error>
								}
							</mat-form-field>
						} @else {
							<mat-form-field class="gw-chkout-field">
								<mat-label>State</mat-label>
								<input matInput
									[formControl]="shipping.controls.address.controls.state">
								@if (shipping.controls.address.controls.state.errors?.['required']) {
									<mat-error>Please provide a state.</mat-error>
								} @else if (shipping.controls.address.controls.state.errors?.['pattern']) {
									<mat-error>Special characters are not allowed.</mat-error>
								}
							</mat-form-field>
						}
					</div>

					<div class="gw-chkout-form">
						<mat-form-field class="gw-chkout-field">
							<mat-label>Postal Code</mat-label>
							<input matInput
								[formControl]="shipping.controls.address.controls.postal_code">
								@if (shipping.controls.address.controls.postal_code.errors?.['required']) {
									<mat-error>Please provide a postal code.</mat-error>
								} @else if (shipping.controls.address.controls.postal_code.errors?.['pattern']) {
									<mat-error>Please provide a valid postal code.</mat-error>
								}
						</mat-form-field>

						<div class="gw-chkout-field">
							<gw-chkout-location-select [field]="shipping.controls.address.controls.country"
								[filterUS]="false"
								[hasBorder]="false"
								label="Country"
								type="country"
								(onSelect)="onCountryChange($event)"
								(locationsLoaded)="onLocationsLoaded()">
							</gw-chkout-location-select>
						</div>
						@if (shipping.controls.address.controls.country.value !== 'US') {
							<mat-form-field class="gw-chkout-field">
								<mat-label>Phone Number</mat-label>
								<input matInput
									type="text" 
									[prefix]="countryCode()" 
									instantPrefix="true" 
									[mask]="countryPattern()"
									[clearIfNotMatch]="true"
									[dropSpecialCharacters]="false" 
									[formControl]="shipping.controls.address.controls.phone_number" />
									<mat-hint>
										{{ countryCode() }} {{ countryPattern() }}
									</mat-hint>
							</mat-form-field>
						}
					</div>
				</div>
			}
		}

		<!-- Offer customers to enter a military address for IDP orders -->
		@if (idp) {
			<mat-checkbox color="primary"
				class="gw-chkout-ship__military-chckbx"
				(change)="onMilitaryChckbxChange($event)"
				formControlName="is_military">
				I want to ship to a military base
			</mat-checkbox>

			@if (shipping.controls.is_military.value) {
				<gwc-military-address [militaryAddressForm]="shipping.controls.military_address"
					(onValidForm)="handleMilitaryAddress($event)">
				</gwc-military-address>
			}

			<h3 class="gw-chkout-label">
				Driver's License
			</h3>
			<p>
				Please provide the zip code indicated on your driver's license to identify the processing location.
			</p>
			<mat-form-field class="gw-chkout-field gw-chkout-field--half"
				[hideRequiredMarker]="true">
				<mat-label>
					Zip code
				</mat-label>
				<input matInput
					name="dl_zip_code"
					pattern="^\d{5}$"
					[formControl]="idp_zip">
				<mat-error>Please provide the zip code.</mat-error>
			</mat-form-field>
		}

		@if (show_shipping()) {
			<div class="gw-chkout-ship__options">
				<h3 class="gw-chkout-label">Shipping Options</h3>
				@if (fedex_logo) {
					<img class="gw-chkout-ship__options-logo"
						src="assets/svg/fedex.svg"
						alt="Fedex">
				}
			</div>

			@if (!shipping.value.address.address_1 || (shipping_type() === 'dynamic' && !ready_for_dynamic())) {
				<p>
					{{ idp ? "Options will appear once an address and the driver's license information are provided." : 'Options will appear once an address is entered.' }}
					<span class="gw-chkout-ship__arrow">
						↳
					</span>
				</p>
			} @else if (shipping_type() === 'basic') {
				<div [class.gw-chkout-ship__select--hidden]="!shipping.value.address.address_1 && !shipping_address">
					<p><strong>Inbound</strong> shipping option available.</p>
					@if (show_inbound()) {
						<gwc-shipping-options [options]="shipping_options"
							description=""
							class="gwc-shipping__mrg-btn"
							[field]="shipping.controls.inbound_speed"
							[submitted]="submitted">
						</gwc-shipping-options>
					}
					<p><strong>Outbound</strong> shipping option available.</p>
					<gwc-shipping-options [options]="shipping_options"
						description=""
						[field]="shipping.controls.speed"
						[submitted]="submitted">
					</gwc-shipping-options>
				</div>
			} @else if (shipping_type() === 'dynamic') {
				@if (loading_dynamic_options() === true) {
					<mat-spinner class="gwc-shipping__loader"
						[diameter]="54"
						color="primary"></mat-spinner>
				} @else {
					@if (product.value.type === 'us_passport') {
						<p><strong>Inbound shipping</strong></p>
					}
					@if (['ca_passport', 'us_passport'].includes(product.value.type)) {
						<gwc-shipping-options [options]="inbound_dynamic_options()"
							[description]="product.value.type === 'ca_passport' ? 'Please select the shipping option that works for you.' : ''"
							[field]="shipping.controls.inbound_speed"
							[submitted]="submitted"
							type="dynamic"
							bound="inbound"
							[hide_logo]="product.value.type === 'us_passport'">
						</gwc-shipping-options>
					}

					@if (product.value.type === 'us_passport') {
						<p style="margin-top: 16px;"><strong>Outbound shipping</strong></p>
					}
					@if (['idp', 'us_passport'].includes(product.value.type)) {
						<gwc-shipping-options [options]="outbound_dynamic_options()"
							[description]="idp ? 'Please select a shipping option that works for you. All options are trackable for peace of mind. Note: shipping time is in addition to application processing.' : ''"
							[field]="shipping.controls.speed"
							[submitted]="submitted"
							type="dynamic"
							bound="outbound"
							[hide_logo]="product.value.type === 'us_passport'">
						</gwc-shipping-options>
					}
				}
			}
		} 
	</form>
	@if (domain === 'rmp_ca' && product?.value.applying_from === 'CA') {
		<div class="gwc-shipping__separator">
			Or
		</div>
		<button mat-button
			(click)="onSubmitInPerson()"
			color="primary"
			class="gw-chkout-main-btn gwc-shipping__skip">
			Submit In-Person
		</button>
	}
</div>
