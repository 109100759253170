import { Component, Input, OnInit } from '@angular/core'
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router'
import { OrderService } from 'src/app/services/order.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-layout',
  templateUrl: './checkout.layout.component.html',
  styleUrls: ['./checkout.layout.component.scss']
})

export class CheckoutLayoutComponent implements OnInit {
  @Input() steps: string[]
  
  public data
  public submitting = false
  public trustpilot = environment.tags ? environment.tags.trustpilot : null
  
  isRMPhotoEnv = environment?.source?.type === 'photo'
  isPickupOptSelected: boolean = undefined

  constructor(
    private router: Router,
    private activated_route: ActivatedRoute,
    private orderService: OrderService,
    private shippingService: ShippingService
  ) { }

  ngOnInit() {
    this.initiateRoute()
    this.listenForProductChanges()
  }

  initiateRoute(): void {
    this.getRouterData()

    this.router.events
      .subscribe((event) => {
        if (event instanceof RoutesRecognized) {
          let route = event.state.root
          while (route.firstChild) {
           route = route.firstChild
          }

          this.data = route.data
        }
      })
  }

  private listenForProductChanges(): void {
    this.orderService.isPickupOptSelected$.subscribe(isSelected => {
      this.isPickupOptSelected = this.isRMPhotoEnv && isSelected
    })
  }

  getRouterData(): any {
    while (this.activated_route.firstChild) {
      this.activated_route = this.activated_route.firstChild
    }

    this.data = this.activated_route.snapshot.data
  }

  showSummary(): boolean {
    return this.data?.step === 2 || this.data?.step === 3
  }

  get headline(): string {
    return this.isRMPhotoEnv && this.isPickupOptSelected && this.data?.step === 3 ? this.data?.pickup?.headline : this.data?.headline
  }

  get description(): string {
    return this.isRMPhotoEnv && this.isPickupOptSelected && this.data?.step === 3 ? this.data?.pickup?.description : this.data?.description
  }

  public getPreviousStep(): string {
    if (this.data?.step === 3) {
      return this.shippingService.shipping_required() ? '/step-3' : '/step-2'
    } else {
      return this.data?.previous
    }
  }

}
